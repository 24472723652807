@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@layer components {
    /* input wrapper */
    .input-wrapper {
        @apply my-2 tracking-wide;
    }
    .input-wrapper > * {
        @apply block w-full;
    }
    .input-wrapper > label {
        @apply mb-2 text-gray-800;
    }
    .input-wrapper > label > span {
        @apply text-red-500;
    }
    .input-wrapper > input {
        @apply border-b py-1 px-2 focus:outline-none focus:border-primary-pink-50 transition-colors text-gray-700;
    }

    .input-error {
        @apply text-red-600 text-sm my-2;
    }

    .forms-bg-gradient {
        background: linear-gradient(22deg, rgba(219, 14, 112, 1) 0%, rgba(230, 103, 32, 1) 100%);
    }
    .steps-input,
    .react-date-picker {
        @apply w-full border border-gray-300 py-1 px-2 rounded-sm min-w-[200px] focus:outline-none focus:border-2 focus:border-[#2684ffb0] transition-colors;
    }
}

.react-date-picker__wrapper {
    border: none !important;
}

.steps-form-select__control {
    min-width: 200px;
    white-space: nowrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* animation */
.fade {
    opacity: 0;
    transition: all 400ms;
    animation: fadeIn 400ms ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* dropdown */
.dropdown-body {
    display: none;
}
.dropdown-wrapper:hover .dropdown-body {
    display: block;
}
